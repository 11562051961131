import React, { forwardRef, useCallback, useEffect } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import classnames from 'classnames/bind'

import { useInView } from 'react-intersection-observer'
import useLottieAnimation from 'hooks/useLottieAnimation'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const LottieImg = forwardRef(
  ({ lottieAnimation, forceShadow, forcePlayAnimation, play }, ref) => {
    const [lottieRef, animation] = useLottieAnimation(lottieAnimation)
    const [inViewRef, inView] = useInView({
      triggerOnce: lottieAnimation.playOnce,
    })
    useEffect(() => {
      play ? animation.current.play() : animation.current.stop()
    }, [play])

    useIsomorphicLayoutEffect(() => {
      if (lottieAnimation.autoPlay && !forcePlayAnimation) {
        inView ? animation.current.play() : animation.current.stop()
      }
    }, [inView])

    const setRefs = useCallback(
      (node) => {
        inViewRef(node)
        ref && (ref.current = node)
        lottieRef && (lottieRef.current = node)
      },
      [inViewRef]
    )

    return (
      <figure
        ref={setRefs}
        className={cx(css.lottieWrapper, { forceShadow })}
      />
    )
  }
)

LottieImg.defaultProps = {
  forcePlayAnimation: false,
  play: false,
}

export default LottieImg
