import { useRef } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import { nanoid } from 'nanoid'

import lottieLight from 'lottie-web/build/player/lottie_light'

const useLottieAnimation = (config = {}) => {
  const ref = useRef()
  const animation = useRef()
  const loopTimeout = useRef()
  const name = config.name || useRef(nanoid())

  useIsomorphicLayoutEffect(() => {
    if (config.animationData || config.path) {
      lottieLight.setQuality('low')
      animation.current = lottieLight.loadAnimation({
        name,
        container: ref.current,
        renderer: 'svg',
        loop: config.loopDelay ? false : config.loop,
        autoplay: false,
        path: config.path || null,
        animationData: config.animationData || null,
        rendererSettings: {
          progressiveLoad: true,
          preserveAspectRatio: 'xMidYMid meet',
        },
      })

      if (config.loop && config.loopDelay) {
        animation.current.addEventListener('complete', () => {
          loopTimeout.current = setTimeout(() => {
            animation.current.goToAndPlay(0)
          }, config.loopDelay)
        })
      }
    }

    return () => {
      lottieLight.destroy(name.current)
      clearTimeout(loopTimeout.current)
    }
  }, [])

  return [ref, animation]
}

export default useLottieAnimation
